import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <a href="https://www.linkedin.com/in/gerard-mason-96527597/">Hi.</a>
    </div>
  );
}

export default App;
